import {UTILS} from "../commons/utils";
import {CONFIG} from "../commons/config";


export const INTERVIEW_APIS = {
  retrieve: (id) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/interviews/${id}/`,
      method: 'GET',
    })
  },

  continue: (id, data) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/interviews/${id}/`,
      method: 'POST',
      data: data,
    })
  },

  end: (id, data) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/interviews/${id}/end/`,
      method: 'PATCH',
      data: data
    })
  },

  recording: (data) => {
    return UTILS.request({
      baseURL: CONFIG.APP_BACKEND_URL,
      url: `/api/v1/interviews/record/`,
      method: 'POST',
      data: data
    })
  }
}
